export const extraServices = () => {
    return (
        <div>
            <h4
                className="bui-f-font-heading"
                style={{ color: "rgb(var(--bui_color_black))" }}
            >
                <div style={{ marginBottom: 16 }}>One-way rules</div>
            </h4>
            <div style={{ margin: "8px 0px" }}>
                <div>
                    <h5 className="PM_b8de4613 bui-f-font-strong">
                        <div style={{ marginBottom: 16 }}>One-way rules</div>
                    </h5>
                    <div style={{ margin: "8px 0px" }}>
                        <div>
                            <div style={{ margin: "8px 0px" }}>
                                <div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            If you arrange to pick the car up in one location and drop it
                                            off in another, the car hire company may charge a one-way fee
                                            to cover the cost of returning the car to the original
                                            location. <br />
                                            Prior notification is required for all one-way rentals. If you
                                            drop the car off at a location other than the one originally
                                            agreed; you will incur a 'Penalty' from the car hire company.
                                        </p>
                                        <div />
                                    </div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>International one-way rentals are not available.</p>
                                        <div />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h4
                className="bui-f-font-heading"
                style={{ color: "rgb(var(--bui_color_black))" }}
            >
                <div style={{ marginBottom: 16 }}>Cross-Border and Boundary rules</div>
            </h4>
            <div style={{ margin: "8px 0px" }}>
                <div>
                    <h5 className="PM_b8de4613 bui-f-font-strong">
                        <div style={{ marginBottom: 16 }}>Cross-border</div>
                    </h5>
                    <div style={{ margin: "8px 0px" }}>
                        <div>
                            <div style={{ margin: "8px 0px" }}>
                                <div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            Please note that cross-border travel is not allowed with this
                                            car hire company.
                                        </p>
                                        <div />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h5 className="PM_b8de4613 bui-f-font-strong">
                        <div style={{ marginBottom: 16 }}>Boundary Rules</div>
                    </h5>
                    <div style={{ margin: "8px 0px" }}>
                        <div>
                            <div style={{ margin: "8px 0px" }}>
                                <div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            The car may not be taken out of the country in which the
                                            rental began.
                                        </p>
                                        <div />
                                    </div>
                                </div>
                            </div>
                            <div style={{ margin: "8px 0px" }}>
                                <div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            Customers are not permitted to take the car from the Mainland
                                            to Tasmania or vice versa, or to enter Western Australia or
                                            the Northern Territory. <br />
                                            <br />
                                            Cars are not allowed to be taken:
                                        </p>
                                        <ul>
                                            <li>on any unsealed roads;</li>
                                            <li>above the snowline;</li>
                                            <li>off-road;</li>
                                            <li>through any river, stream, creek or tidal crossing;</li>
                                            <li>
                                                through flood waters or on flood prone roads or on any road
                                                where the state or condition of the road make the use of the
                                                car unsafe;
                                            </li>
                                            <li>
                                                on any road where the police or any government or statutory
                                                authority has issued a warning, caution or which has been
                                                closed;
                                            </li>
                                            <li>
                                                to any islands, except for:
                                                <br />
                                                - Kangaroo Island <br />
                                                - Stradbroke Island <br />
                                                - Magnetic Island <br />
                                                - Bribie Island <br />
                                                - Phillip Island <br />
                                                - Bruny Island <br />
                                            </li>
                                        </ul>
                                        The car also can’t be taken to specific places in Queensland:
                                        <ul>
                                            <li> north of Chillagoe or west of Georgetown; </li>
                                            <li>north of Cooktown or Laura; </li>
                                            <li>on the Burke Development Road</li>
                                            <li>north of Maggieville; </li>
                                            <li>on Unsealed Roads north and west of Mt Isa; </li>
                                            <li>on the Bloomfield track; or on the Savannah Way. </li>
                                        </ul>
                                        <p />
                                        <div />
                                    </div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            Cars are not allowed to go into snow areas or past the snow
                                            line or to any area that requires the use of snow chains
                                            during the winter season from June to October.
                                        </p>
                                        <div />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h4
                className="bui-f-font-heading"
                style={{ color: "rgb(var(--bui_color_black))" }}
            >
                <div style={{ marginBottom: 16 }}>Outside Office Hours</div>
            </h4>
            <div style={{ margin: "8px 0px" }}>
                <div>
                    <div style={{ margin: "8px 0px" }}>
                        <div>
                            <div style={{ margin: "8px 0px" }}>
                                <div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>An out-of-hours service is not available.</p>
                                        <div />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h4
                className="bui-f-font-heading"
                style={{ color: "rgb(var(--bui_color_black))" }}
            >
                <div style={{ marginBottom: 16 }}>Deliveries and Collections</div>
            </h4>
            <div style={{ margin: "8px 0px" }}>
                <div>
                    <div style={{ margin: "8px 0px" }}>
                        <div>
                            <div style={{ margin: "8px 0px" }}>
                                <div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>A delivery / collection service is not available.</p>
                                        <div />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h4
                className="bui-f-font-heading"
                style={{ color: "rgb(var(--bui_color_black))" }}
            >
                <div style={{ marginBottom: 16 }}>Taxes &amp; Fees</div>
            </h4>
            <div style={{ margin: "8px 0px" }}>
                <div>
                    <div style={{ margin: "8px 0px" }}>
                        <div>
                            <div style={{ margin: "8px 0px" }}>
                                <div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            Any additional services will be subject to any applicable
                                            sales taxes and location fees.
                                        </p>
                                        <div />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h4
                className="bui-f-font-heading"
                style={{ color: "rgb(var(--bui_color_black))" }}
            >
                <div style={{ marginBottom: 16 }}>Pet policy</div>
            </h4>
            <div style={{ margin: "8px 0px" }}>
                <div>
                    <div style={{ margin: "8px 0px" }}>
                        <div>
                            <div style={{ margin: "8px 0px" }}>
                                <div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            No animals, except service dogs, are allowed in the car, as
                                            this can cause severe allergic reactions in future customers.
                                            If you let any animal in the car, the rental company will
                                            charge a penalty fee and a cleaning fee.
                                        </p>
                                        <div />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h4
                className="bui-f-font-heading"
                style={{ color: "rgb(var(--bui_color_black))" }}
            >
                <div style={{ marginBottom: 16 }}>Rental extensions</div>
            </h4>
            <div style={{ margin: "8px 0px" }}>
                <div>
                    <div style={{ margin: "8px 0px" }}>
                        <div>
                            <div style={{ margin: "8px 0px" }}>
                                <div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            If you need the car, including any extras, for longer than
                                            originally agreed, please call the pick-up counter (you’ll
                                            find their phone number on your rental agreement). If you
                                            don’t give them enough notice, there may be a penalty fee on
                                            top of the additional cost. You’ll also need to sign a new
                                            rental agreement with the rental company and arrange insurance
                                            to cover the additional day(s).
                                        </p>
                                        <div />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
