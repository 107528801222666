export const notIncludedInRentalPrice = () => {
    return (
        <div>
            <p>Local Charges<br />
                This section shows charges that you&rsquo;ll pay at the counter because of (a) where you're picking your car up, (b) who's driving, and (c) where you go during your rental. It doesn&rsquo;t show other over-the-counter costs, such as charges for fuel, child seats or additional services.</p>

            <p><div style={{ fontWeight: 'bold' }}>Fines</div><br />
                You are responsible for any charges and fines, including tolls, congestion charges, speeding tickets and parking or traffic fines.</p>

            <p>Mandatory Coverage<br />
                At the car hire counter, you may decide to buy additional cover to reduce or remove your excess, or to cover things your Collision Damage Waiver (CDW) doesn't, such as tyres and windscreen.
                Please note: If you do, the contract will be between you and the rental company – so you’ll need to contact them if you're dissatisfied with the policy or the cover it provides.
            </p>

            <p>Additional drivers<br />
                The main driver (the person named on the booking) must be present when you pick the car up, and must be the one who pays anything charged at the counter. There may be a daily fee for additional drivers. The rental terms, including age-related restrictions and fees, apply to any additional drivers.</p>

            <p>An additional driver fee will apply for any additional driver: A$12.00 per day, including tax, up to a maximum of $250.00 per rental.</p>

            <p>Additional Fees at Drop-off<br />
                <br />

                <div style={{ fontWeight: 'bold' }}>After your rental</div>
            </p>
            <p>If you&rsquo;ve incurred any charges, such as speeding tickets or unpaid congestion charges, the car hire company will try to contact you when the authorities ask them who was driving. This could be months after your rental &ndash; and you&rsquo;ll have to pay the company&rsquo;s administration fee as well as the original charge.</p>
        </div>
    )
}
