export const fuelPolicy = () => {
    return (
        <div>
            <p>Like for like<br />
                When you pick your car up, the fuel tank will be full or partly full. You will leave a deposit to cover the cost of the fuel: the counter staff will block this money on your credit card. Just before you return your car, please replace the fuel you've used.</p>

            <p>The fuel tank will be either full or part-full. When you pick the car up, please check how much fuel there is. On return, as long as you've replaced the fuel you've used, you’ll pay no fuel fees.</p>

            <p>If you return your car with less fuel than it had when you picked it up, the car hire company will keep some or all of your deposit to cover the missing fuel. The cost of the missing fuel will be based on the supplier's current price per litre. This may cost you more than you would pay to refill the tank yourself.</p>

            <p>
                If there’s any fuel missing when you drop your car off, you'll be charged both a refuelling fee and the cost of the missing fuel (at the current market price per litre/gallon).
            </p>

            E-cars<br />

            <p>The customer is obliged to return the car with the charge level stated on the rental agreement, but with at least 20% battery charge. If the car is returned with a lower charge level than agreed, the customer will be charged for recharging the car according to the rates valid at the time of rental.</p>
        </div>
    )
}
