export const includedInRentalPrice = () => {
    return (
        <div>
            <p>Insurance, Covers and Waivers
                <br />
                <br />

                <div style={{ fontWeight: 'bold' }}>Insurance Information</div>
                <br />
                All rental cars must have Collision Damage Waiver (CDW) and Theft Protection (TP). Each policy will be either:<br />
                included or<br />
                purchasable from the rental company, or<br />
                provided by your credit card company.<br />
                Insurance information<br />
                All rental cars must have Collision Damage Waiver (CDW) and Theft Protection (TP). Each policy will be either:<br />
                included or<br />
                purchasable from the rental company, or<br />
                provided by another company (e.g. your credit card company).</p>

            <p>
                <div style={{ fontWeight: 'bold' }}>Collision Damage Waiver (CDW)</div><br />
                If the car’s bodywork gets damaged, the most you’ll pay towards repairs covered by the Collision Damage Waiver is the damage excess. This cover is only valid if you stick to the terms of the rental agreement. It doesn’t cover other parts of the car (e.g. glass, wheels, interior, roof or undercarriage), or charges (e.g. for towing or off-road time), or anything in the car (e.g. child seats, GPS devices or personal belongings).</p>

            <p>
                <div style={{ fontWeight: 'bold' }}>Theft Cover</div><br />
                If the car is stolen, the most you’ll pay towards replacement costs covered by the policy is the theft excess. This cover is only valid if you stick to the terms of the rental agreement.</p>

            <p>
                <div style={{ fontWeight: 'bold' }}>Third-Party Liability (TPL)</div><br />
                Covers the driver&rsquo;s liability for any injuries and property damage that are included in the policy. It does not cover injuries to the driver or damage to the rental car. This cover is only valid if you stick to the terms of the rental agreement.</p>

            <p><div style={{ fontWeight: 'bold' }}>Loss or Damage</div><br />
                If the car is stolen, or seriously damaged, or damaged in an incident involving someone else, please contact the rental company and the police immediately. If you can't provide the necessary documents from the police, you'll be liable for the full cost of replacing/repairing the car. If the car gets slightly damaged, and no-one else is involved, please contact the rental company immediately.</p>

            <p>The rental company is not liable for the loss of / theft of / damage to any belongings in the car, during or after the rental.</p>

            <p>Damage to the car will be charged for by the car hire company after it is dropped off - and will incur a Damage Administration fee on top of the amount deducted from the excess.</p>

            <p>Not included in the rental cover (CDW &amp; TP)<br />
                Loss of / theft of / damage to: keys, aerials, jack, safety triangles and vests, windscreen wipers, petrol cap, luggage covers, or any other fixed or mobile component of the car.</p>

            <p><div style={{ fontWeight: 'bold' }}>Additional Cover</div><br />
                Supplemental Liability Insurance (SLI) provides up to $1,000,000.00 of coverage against third-party claims which are covered by the policy, whether they&rsquo;re for bodily injury or property damage as the result of an accident. This protection applies to all authorised drivers of the rental car.</p>

            <p>Roadside assistance<br />
                When you pick your car up, please make sure you find out where the paperwork is kept. In most cases, it&rsquo;ll be in the glove box or attached to the sun visor.</p>

            <p>Mileage / Kilometres<br />
                Your rental includes unlimited free kilometres.</p>

            <p>Changing the length of your rental can affect how many kilometres you can drive for free. It can also affect how much you will pay for each additional kilometre.</p>

            <p>Included at no extra cost<br />
                <div style={{ fontWeight: 'bold' }}>No Extra Cost</div><br />
                Applicable local fees and taxes.</p>
        </div>
    )
}
