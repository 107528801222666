import AddIcon from '@mui/icons-material/Add';

export const extraEquipment = () => {
    return (
        <div>
            <h4
                className="bui-f-font-heading"
                style={{ color: "rgb(var(--bui_color_black))" }}
            >
                <div style={{ marginBottom: 16 }}>Pay local extras equipment</div>
            </h4>
            <div style={{ margin: "8px 0px" }}>
                <div>
                    <div style={{ margin: "8px 0px" }}>
                        <div>
                            <div style={{ margin: "8px 0px" }}>
                                <div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p />
                                        <p>
                                            As explained during the booking process, extras (child seats,
                                            GPS, etc.) will be either:
                                        </p>
                                        <ul>
                                            <li>included in the cost of your rental, or</li>
                                            <li>ordered and paid for when booking your car, or</li>
                                            <li>
                                                requested when booking your car and paid for at pick-up (in
                                                which case, we can’t guarantee that they’ll be available, or
                                                that the price will be the same).
                                            </li>
                                        </ul>
                                        <p />
                                        <div />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h4
                className="bui-f-font-heading"
                style={{ color: "rgb(var(--bui_color_black))" }}
            >
                <div style={{ marginBottom: 16 }}>Extra Equipment Conditions</div>
            </h4>
            <div style={{ margin: "8px 0px" }}>
                <div>
                    <div style={{ margin: "8px 0px" }}>
                        <div>
                            <div style={{ margin: "8px 0px" }}>
                                <div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            Additional extras will be subject to any applicable sales
                                            taxes and location taxes.
                                        </p>
                                        <div />
                                    </div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            If an extra is lost, damaged or stolen, you'll pay a
                                            replacement fee.
                                        </p>
                                        <div />
                                    </div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            If the car is picked up in one location and dropped off in
                                            another, the rental company may charge a One-Way Fee to cover
                                            the cost of returning an extra to the original location.
                                        </p>
                                        <div />
                                    </div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            Please note: counter staff aren’t trained (or allowed) to fit
                                            baby / child / booster seats. You will need to fit these
                                            yourself.
                                        </p>
                                        <div />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h4
                className="bui-f-font-heading"
                style={{ color: "rgb(var(--bui_color_black))" }}
            >
                <div style={{ marginBottom: 16 }}>Additional drivers</div>
            </h4>
            <div style={{ margin: "8px 0px" }}>
                <div>
                    <div style={{ margin: "8px 0px" }}>
                        <div>
                            <div style={{ margin: "8px 0px" }}>
                                <div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            The car hire company’s age-related charges and restrictions
                                            will apply to all additional drivers.
                                        </p>
                                        <div />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
