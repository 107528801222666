import AddIcon from '@mui/icons-material/Add';

export const importantInfo = () => {
    return (
        <div>
            <h4
                className="bui-f-font-heading"
                style={{ color: "rgb(var(--bui_color_black))" }}
            >
                <div style={{ marginBottom: 16 }}>Important information</div>
            </h4>
            <div style={{ margin: "8px 0px" }}>
                <div>
                    <div style={{ margin: "8px 0px" }}>
                        <div>
                            <div style={{ margin: "8px 0px" }}>
                                <div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            By making this booking, you’re confirming that you have read
                                            and accept the rental terms.
                                            <br />
                                            <br /> At the rental counter, customers will also sign a
                                            rental agreement before they give you the key. It’s important
                                            that you read that agreement carefully - and if anything isn’t
                                            clear, discuss it with the counter staff before you sign.
                                        </p>
                                        <div />
                                    </div>
                                </div>
                            </div>
                            <div style={{ margin: "8px 0px" }}>
                                <div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            You rental company reserves the right to refuse a car if, in
                                            the counter staff’s opinion, the driver is unfit to drive or
                                            anyone in the group is threatening, abusive or in any way
                                            endangering the health and safety of other people. If this
                                            happens, you will not be entitled to any refund or
                                            compensation.
                                        </p>
                                        <div />
                                    </div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            Please see ‘What you need at pick-up’ above - and note that
                                            the counter staff will not provide a car unless all
                                            requirements (age, driving licence, payment card,
                                            documentation, etc.) are met. If this happens, you will not be
                                            entitled to any refund or compensation.
                                        </p>
                                        <div />
                                    </div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            Before you drive off, you must check the car and make sure
                                            that any damage is reported to the counter staff and noted on
                                            the rental agreement. Otherwise, you may end up being liable
                                            for that damage.
                                        </p>
                                        <div />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h5 className="PM_b8de4613 bui-f-font-strong">
                        <div style={{ marginBottom: 16 }}>Road rules</div>
                    </h5>
                    <div style={{ margin: "8px 0px" }}>
                        <div>
                            <div style={{ margin: "8px 0px" }}>
                                <div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p />
                                        <p>
                                            When you rent a car, you are agreeing to use it responsibly.
                                            You must not:
                                        </p>
                                        <ul>
                                            <li>
                                                drive under the influence of alcohol, drugs, or any other
                                                type of narcotic substances
                                            </li>
                                            <li>
                                                transport any inflammable or dangerous goods, or any toxic,
                                                corrosive, radioactive or otherwise harmful substances
                                            </li>
                                            <li>
                                                carry anything which, because of its smell and/or condition,
                                                could damage the car or cause the rental company to lose
                                                time or money
                                            </li>
                                            <li>transport live animals</li>
                                            <li>
                                                install a roof rack, luggage carrier or anything similar,
                                                unless supplied by the rental company
                                            </li>
                                            <li>rent the car to anyone else</li>
                                            <li>carry passengers for hire or reward (taxi)</li>
                                            <li>participate in rallies, competitions or trials</li>
                                            <li>give driving lessons</li>
                                            <li>tow another vehicle</li>
                                            <li>
                                                travel on unpaved roads or roads where the surface or state
                                                of repair could damage the car
                                            </li>
                                            <li>
                                                in any way break the highway code, road traffic laws or
                                                other laws.
                                            </li>
                                        </ul>
                                        <p />
                                        <div />
                                    </div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            Seatbelt - Whatever country you’re driving in, driver and
                                            passengers must wear seatbelts if fitted, wherever they’re
                                            sitting in the car.
                                        </p>
                                        <div />
                                    </div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            Please be aware that all cars have a non-smoking policy. Fines
                                            will apply if this policy is not adhered to.
                                        </p>
                                        <div />
                                    </div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            To make sure you understand the rules and laws about driving
                                            hire cars at home and abroad, it’s essential you read the
                                            Terms &amp; Conditions in full before you leave to pick your
                                            car up.
                                            <br />
                                            <br /> In particular, please make sure you know what documents
                                            and other paperwork you must take with you (e.g. driving
                                            licence, forms of identification, and payment cards) and what
                                            you’ll need to pay for at the car hire counter. If you don’t
                                            have everything you need, the car hire company may not let you
                                            pick the car up.
                                        </p>
                                        <div />
                                    </div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            If you intend to do any city driving, there may be traffic
                                            restrictions based on your car’s licence plate, so please tell
                                            the counter staff when you go to pick up your car.
                                        </p>
                                        <div />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h5 className="PM_b8de4613 bui-f-font-strong">
                        <div style={{ marginBottom: 16 }}>On the road</div>
                    </h5>
                    <div style={{ margin: "8px 0px" }}>
                        <div>
                            <div style={{ margin: "8px 0px" }}>
                                <div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            Accident or Breakdown - In the event of a breakdown, accident
                                            or mechanical difficulties, you must call the car hire company
                                            immediately. They must give authority for repairs or
                                            replacement cars. Please keep copies of all documentation you
                                            are asked to complete. This may be needed if you wish to make
                                            a claim. For accidents, a police report and an incident report
                                            from the car hire counter will be required.
                                        </p>
                                        <div />
                                    </div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            If you are having any problems with your rental car, please
                                            call the rental company.
                                        </p>
                                        <div />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h4
                className="bui-f-font-heading"
                style={{ color: "rgb(var(--bui_color_black))" }}
            >
                <div style={{ marginBottom: 16 }}>
                    Car Hire Company Important Information
                </div>
            </h4>
            <div style={{ margin: "8px 0px" }}>
                <div>
                    <h5 className="PM_b8de4613 bui-f-font-strong">
                        <div style={{ marginBottom: 16 }}>Price calculation</div>
                    </h5>
                    <div style={{ margin: "8px 0px" }}>
                        <div>
                            <div style={{ margin: "8px 0px" }}>
                                <div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            Please note that exchange rates can fluctuate - and no-one in
                                            the car rental industry can do anything about this. As a
                                            result, you might find that charges on your statement aren’t
                                            an exact match with the price you were told while booking. The
                                            same goes for any refund you may receive.
                                        </p>
                                        <div />
                                    </div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            Price Calculation - Prices are based on the pick-up and
                                            drop-off times and dates that you agree before your rental
                                            starts. If you pick the car up any later or bring it back any
                                            earlier, you will not receive a refund for unused time.
                                        </p>
                                        <div />
                                    </div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            If, at pick-up, you choose to take a different car, there may
                                            be an additional charge for this – even if the new car is
                                            smaller than the one you had booked.
                                        </p>
                                        <div />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h5 className="PM_b8de4613 bui-f-font-strong">
                        <div style={{ marginBottom: 16 }}>Penalty fees</div>
                    </h5>
                    <div style={{ margin: "8px 0px" }}>
                        <div>
                            <div style={{ margin: "8px 0px" }}>
                                <div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            An Immobilisation Charge is payable in the event of an
                                            accident. This is in addition to the amount deducted from the
                                            excess and is compulsory. This charge is calculated against
                                            the car group and the number of repair days.
                                        </p>
                                        <div />
                                    </div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            Lost Keys - If you lose your key(s), you will be charged the
                                            cost of replacement.
                                        </p>
                                        <div />
                                    </div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            If you've not returned the car after your agreed drop-off
                                            time, the insurance will no longer be valid. Plus, you will be
                                            charged a penalty fee as well as the cost of another day's
                                            rental.
                                        </p>
                                        <div />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h5 className="PM_b8de4613 bui-f-font-strong">
                        <div style={{ marginBottom: 16 }}>Grace period</div>
                    </h5>
                    <div style={{ margin: "8px 0px" }}>
                        <div>
                            <div style={{ margin: "8px 0px" }}>
                                <div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            You must be at the rental counter by your pick-up time: if
                                            you’re late, the car may no longer be available, and you won’t
                                            be entitled to a refund. If you think you might be late, it's
                                            vital that you contact the rental company at least 30 minutes
                                            before your pick-up time, even if it's because of a flight
                                            delay and you’ve provided your flight number.
                                        </p>
                                        <div />
                                    </div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            There will be a 29-minute grace period for the return of all
                                            vehicles. If the car is returned after this time the insurance
                                            will no longer be valid and the customer will have to pay the
                                            late penalty fee + the daily rate.
                                        </p>
                                        <div />
                                    </div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            In case of delay, reservations will be held for 59 minutes
                                            from the specified reservation time, after which they will be
                                            classed as a no-show.
                                        </p>
                                        <div />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h5 className="PM_b8de4613 bui-f-font-strong">
                        <div style={{ marginBottom: 16 }}>Valeting</div>
                    </h5>
                    <div style={{ margin: "8px 0px" }}>
                        <div>
                            <div style={{ margin: "8px 0px" }}>
                                <div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            The car hire company will charge you if the car needs more
                                            than a reasonable amount of cleaning after your rental.
                                        </p>
                                        <div />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h4
                className="bui-f-font-heading"
                style={{ color: "rgb(var(--bui_color_black))" }}
            >
                <div style={{ marginBottom: 16 }}>Company Registration Details</div>
            </h4>
            <div style={{ margin: "8px 0px" }}>
                <div>
                    <div style={{ margin: "8px 0px" }}>
                        <div>
                            <div style={{ margin: "8px 0px" }}>
                                <div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            For a full list of partners, please click{" "}
                                            <a
                                                href="https://www.rentalcars.com/en/guides/short-breaks/company-registration-details/"
                                                target="_blank"
                                            >
                                                here
                                            </a>
                                        </p>
                                        <div />
                                    </div>
                                    <div style={{ margin: "8px 0px" }}>
                                        <p>
                                            Rental Car Holdings Pty, Ltd., Level 1, 12 Short Street
                                            Southport QLD 4215 AU, ABN 33 129 240 268
                                        </p>
                                        <div />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
